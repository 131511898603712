<template>
  <Dialog
    v-model:visible="displayModal"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '60vw'}"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    @hide="onCloseModal"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-pencil" style="font-size: 1rem;margin-right:10px"></i>
          <strong style="font-size:20px">Asignación Lotes</strong>
        </h4>
      </div>
    </template>
    <div class="grid grid-cols-7 text-xxs bg-gray-300 rounded p-2">
      <div class="flex flex-col col-span-4">
        <div class="font-bold">
          Medicamento: <span class="font-light">{{ Item.Description }}</span>
        </div>
      </div>
      <div class="flex flex-col">
        <strong> Cantidad Total</strong>
        <span>{{ $h.formatNumber(Item.BatchNumbers.reduce((acc, val) => acc + val.Quantity, 0)) }}</span>
      </div>
    </div>
    <div class="grid grid-cols-7 text-xxs rounded gap-2">
      <div class="col-span-4 flex align-items-center mt-4">
        <InputText class="p-inputtext-xs w-full"
                   v-model="Lote.DistNumber"/>
      </div>
      <div class="flex align-items-center mt-4">
        <input style="padding: 0.30rem;" v-model="Lote.ExpDate"
               class="p-button-secondary border rounded-md p-inputtext-xs w-full" type="date">
      </div>
      <div class="flex align-items-center mt-4">
          <span class="p-input-icon-left">
            <InputNumber :step="1" class="p-inputtext-xs " inputId="minmax"
                         showButtons buttonLayout="horizontal" :min="1"
                         v-model="Lote.Quantity"
                         decrementButtonClass="bg-blue-800" incrementButtonClass="bg-blue-800"
                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
          </span>
      </div>
      <div class="flex justify-end align-items-center mt-4">
          <span class="p-input-icon-left">
            <Button icon="pi pi-plus" label="Agregar Lote" @click="addBatchNumber"
                    class="p-button-outlined p-button-success p-button-xs"/>
          </span>
      </div>
    </div>
    <div class="pt-4">
      <DataTable
        :value="Item.BatchNumbers"
        class="p-datatable-sm text-xxs"
        showGridlines
        dataKey="id"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="500px"
        editMode="row"
        @row-edit-save="onRowEditSave"
        v-model:editingRows="editingRows"
        @row-edit-cancel="onRowEditCancel"
      >
        <template #empty>
          No hay datos para mostrar
        </template>
        <Column field="BatchNumber" header="Lotes" headerStyle="justify-content: center;" style="min-width:30rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="{data}">
            <div class="flex justify-center">
              {{ data.DistNumber }}
            </div>
          </template>
          <template #editor="{data}">
            <div class="flex justify-center">
              <InputText class="text-xxs p-inputtext-xs w-full"
                         v-model="data.DistNumber"/>
            </div>
          </template>
        </Column>
        <Column field="Quantity" header="Cantidad" headerStyle="justify-content: center;" style="width:5rem"
                bodyStyle="text-align: center;justify-content: center">
          <template #body="{data}">
            <div class="flex justify-center">
              {{ data.Quantity }}
            </div>
          </template>
          <template #editor="{data}">
            <div class="flex justify-center">
              <InputNumber inputId="integeronly" class="text-xxs p-inputtext-xs w-full"
                           :min="1" v-model="data.Quantity"/>
            </div>
          </template>
        </Column>
        <Column field="ExpDate" header="Fecha Vencimiento" headerStyle="justify-content: center;"
                style="min-width:10rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="{data}">
            <div>
              {{ data.ExpDate }}
            </div>
          </template>
          <template #editor="{data}">
            <div>
              <input style="padding: 0.30rem;" v-model="data.ExpDate"
                     class="p-button-secondary border rounded-md p-inputtext-xs w-full" type="date">
            </div>
          </template>
        </Column>
        <Column field="" header="Acciones" headerStyle="justify-content: center;" :rowEditor="true"
                style="min-width:10rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" @click="editingRows = [slotProps.data]"
                    class="p-button-rounded p-button-text p-button-plain" v-tooltip="'Editar lote'"/>
            <Button icon="pi pi-trash" v-tooltip.top="'Eliminar lote'"
                    iconClass="text-xl"
                    @click="deleteLotes(slotProps)"
                    class="p-button-text p-button-danger">
            </Button>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex justify-center mt-4">
      <Button icon="pi pi-check-square" label="Confirmar" @click="confirmLine" class="p-button-outlined p-button-xs"/>
    </div>
  </Dialog>
</template>

<script>
import { reactive, ref } from 'vue'
import Swal from 'sweetalert2'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
export default {
  name: 'modalAsignarLotes',
  setup () {
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const editingRows = ref([])
    const displayModal = ref(false)
    const Item = ref([])
    const Lote = reactive({
      Quantity: null,
      DistNumber: null,
      ExpDate: null,
      OnHandQty: null
    })
    const addBatchNumber = () => {
      if (!Lote.DistNumber) {
        Swal.fire({
          icon: 'info',
          text: 'Por favor ingrese un lote e inténtelo de nuevo.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      Item.value.BatchNumbers.push({
        Quantity: Lote.Quantity,
        DistNumber: Lote.DistNumber,
        ExpDate: Lote.ExpDate,
        QuantityTemp: Lote.Quantity,
        DistNumberTemp: Lote.DistNumber,
        ExpDateTemp: Lote.ExpDate
      })
      Lote.DistNumber = null
      Lote.Quantity = null
      Lote.ExpDate = null
      Lote.QuantityTemp = null
      Lote.DistNumberTemp = null
      Lote.ExpDateTemp = null
    }
    const onRowEditSave = ({ index }) => {
      Item.value.BatchNumbers[index].QuantityTemp = Item.value.BatchNumbers[index].Quantity
      Item.value.BatchNumbers[index].DistNumberTemp = Item.value.BatchNumbers[index].DistNumber
      Item.value.BatchNumbers[index].ExpDateTemp = Item.value.BatchNumbers[index].ExpDate
    }
    const onRowEditCancel = ({ index }) => {
      Item.value.BatchNumbers[index].Quantity = Item.value.BatchNumbers[index].QuantityTemp
      Item.value.BatchNumbers[index].DistNumber = Item.value.BatchNumbers[index].DistNumberTemp
      Item.value.BatchNumbers[index].ExpDate = Item.value.BatchNumbers[index].ExpDateTemp
    }
    const deleteLotes = ({ index, data }) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea eliminar este lote?',
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          if (data.id) await _RecepcionPedidosService.deleteLoteRecepcionAdmin(data.id)
          Item.value.BatchNumbers.splice(index, 1)
          await Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Se eliminó el lote correctamente',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
          })
        }
      })
    }
    const openModal = (value) => {
      Item.value = value
      displayModal.value = true
    }
    const confirmLine = () => {
      displayModal.value = false
    }
    const onCloseModal = () => {
      Lote.Quantity = null
      Lote.DistNumber = null
      Lote.ExpDate = null
    }
    return {
      displayModal,
      Item,
      confirmLine,
      openModal,
      Lote,
      addBatchNumber,
      onCloseModal,
      deleteLotes,
      editingRows,
      onRowEditCancel,
      onRowEditSave
    }
  }
}
</script>

<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-inputnumber-input) {
  width: 3rem;
}

::v-deep(.p-inputnumber-buttons-horizontal) {
  justify-content: center;
}
</style>
